"use client"
import Modal from "@/components/Modal/Modal";
import PlanCardProps from "@/components/Plan";
import Image from "next/image";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useTranslations } from "next-intl";
import axios from "axios";
import { getSession } from "@/app/[locale]/auth/session";
import {BASE_URL} from "@/constants";

interface modalProps {
  plan: any;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  image: any;
}

const Popup: React.FC<modalProps> = ({
  plan,
  setShowModal,
  showModal,
  image,
}) => {
  const { operatorTitle, title, price, packageTitle, esimType,countryCode,packageSlug } = plan;
  const t =useTranslations();

  async function makePayment() {
      const session = await getSession();
      if(!session){
        window.location.href='/signin'
        // console.log(`xxxx`)
      }

    const response = await axios.post(BASE_URL + `stripe`,{slug: packageSlug},{
      headers:{
        Authorization: `Bearer ${session.accessToken}`
      }
    });
    const data = response.data;
    if (data?.data?.url){
      window.location.href=data?.data?.url
    }

  }
  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      <div className="relative max-h-[700px] overflow-auto rounded-lg bg-white ">
        <div className="flex items-center justify-between rounded-t p-4 dark:border-gray-600 md:p-5">
          <h3 className="text-2xl font-semibold leading-8 text-primary">
            {operatorTitle}
          </h3>
        </div>
        <div className="flex gap-4 p-4 ">
          <Image
            src={image}
            alt={`Flag of ${image}`}
            width={350}
            height={200}
          />
          <ul
            role="list"
            className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-15"
          >
            <li className="flex gap-x-3 text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>

              <div className="flex w-full justify-between">
                {/*<span>{t('coverage')}</span>*/}
                <span className="font-bold">{t(`countries_list.${countryCode}`)}</span>
              </div>
            </li>
            <li className="flex gap-x-3 text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
                />
              </svg>
              <div className="flex w-full justify-between">
                {/*<span>{t('data')}</span>*/}
                <span className="font-bold">{packageTitle}</span>
              </div>
            </li>
            <li className="flex gap-x-3 text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                />
              </svg>
              <div className="flex w-full justify-between">
                {/*<span>{t('esim_type')}</span>*/}
                <span className="font-bold">{esimType}</span>
              </div>
            </li>
          </ul>
        </div>

        <div className="flex justify-between gap-4 p-4">
          <p className="mt-6 flex w-1/2 items-baseline gap-x-1">
            <span className="text-2xl text-gray-900">{price}$ USD</span>
          </p>
          <button
            onClick={makePayment}
            aria-describedby="tier-extended"
            className="mt-6 block w-1/2 rounded-md bg-primary px-3 py-2 text-center text-base font-medium leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('buy_now')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
