import React, { Dispatch, SetStateAction } from "react";
import Image from "next/image";
import { IPackage } from "@/types/package";
import { useTranslations } from "next-intl";

interface PlanCardComponentProps {
  plan: IPackage;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedPlan: Dispatch<SetStateAction<IPackage>>;
}

const PlanCard: React.FC<PlanCardComponentProps> = ({
  plan,
  setShowModal,
  setSelectedPlan,
}) => {
  const {
    operatorTitle,
    operatorImage,
    title,
    price,
    packageTitle,
    esimType,
    packageData,
    day,
    countryCode
    // popular,
  } = plan;

  const t = useTranslations();
  const countriesList = useTranslations("countries_list");

  const handleBtn = () => {
    setShowModal(true);
    setSelectedPlan(plan);
  };

  return (
    <>
      <div className={`rounded-3xl p-8 ring-1 ring-primary  xl:p-10`}>
        <div className="flex justify-between gap-4">
          <div className="flex items-center justify-between gap-x-4">
            <h3
              id="tier-extended"
              className="text-2xl font-semibold leading-8 text-primary"
            >
              {operatorTitle}
            </h3>
            {/*{popular && (*/}
            {/*<p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">*/}
            {/*  Most popular*/}
            {/*</p>*/}
            {/*)}*/}
          </div>
          <Image
            src={operatorImage}
            alt={`Flag of ${operatorTitle}`}
            width={150}
            height={100}
          />
        </div>
        <p className="mt-4 text-base leading-6 text-gray-600">{t(`countries_list.${countryCode}`)}</p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-5xl font-bold tracking-tight text-gray-900">
            {price}
          </span>
          <span className="text-3xl font-bold tracking-tight text-gray-900">
            $
          </span>
        </p>
        <button
          onClick={handleBtn}
          aria-describedby="tier-extended"
          className=" mt-6 block w-full rounded-md bg-primary px-3 py-2 text-center text-base font-medium leading-6 text-white shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          {t("buy_now")}
        </button>
        <ul
          role="list"
          className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
        >
          <li className="flex gap-x-3 text-base">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>

            <div className="flex w-full justify-between">
              <span>{t("coverage")}</span>
              <span className="font-bold">{t(`countries_list.${countryCode}`)}</span>
            </div>
          </li>
          <li className="flex gap-x-3 text-base">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
              />
            </svg>
            <div className="flex w-full justify-between">
              <span>{t("data")}</span>
              <span className="font-bold">
                {packageData} - {t("validity_in_days", { data: day })}
              </span>
            </div>
          </li>
          <li className="flex gap-x-3 text-base">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
              />
            </svg>
            <div className="flex w-full justify-between">
              <span>{t("esim_type")}</span>
              <span className="font-bold">{esimType}</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PlanCard;
